/* Note: use only to override very specific theme styles. 
All other css rules should be written in their own respective sass documents. */

/* #root::-webkit-scrollbar {
  display: none !important;
} */

.custom-image {
  width: 600px;
}

.responsive-content-section--background-image {
  position: relative;
}

.decorative-element_container {
  position: relative;
  width: 100%;
  height: 500px;
}

/* CUSTOM STYLIZED HEADER */
.custom-header--primary-light {
  font-family: 'Rubik';
  color: #0183BF;
  font-weight: 500;
}

.custom-header--primary {
  color: #01528A;
}